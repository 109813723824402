import * as React from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';
import Section from '../components/Section';
import Wrapper from '../components/Wrapper';
import Hero from '../components/Hero';
import HeroImage from '../components/HeroImage';
import background from '../assets/images/contact/hero.jpeg';
import ig from '../assets/images/icon-ig.svg';
import email from '../assets/images/icon-email.svg';

const Main = styled.main`
  background: #fff;
  color: #101119;
  line-height: 1.25;

  h1,
  h2 {
    font-family: 'Inter' !important;
    color: inherit;
  }

  h1 {
    font-size: 2.5rem;
    line-height: inherit;
    text-shadow: none;

    @media (max-width: 650px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: inherit;
    text-shadow: none;

    @media (max-width: 650px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const ColSmall = styled.div`
  width: 33.33%;
  padding: 0 2rem;

  @media (max-width: 900px) {
    width: 100%;
    padding: 1rem 0;
  }
`;

const ColLarge = styled.div`
  width: 66.66%;
  padding: 0 2rem;

  @media (max-width: 900px) {
    width: 100%;
    padding: 1rem 0;
  }
`;

const Form = styled.form`
  margin-left: 4rem;

  @media (max-width: 900px) {
    margin: 0;
  }
`;

const Lead = styled.p`
  font-size: 1.3 5rem !important;
  padding: 0;
`;

const Field = styled.div`
  position: relative;
  margin: 0 auto 2rem;
`;

const Label = styled.label`
  font-size: 16px;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  opacity: 0.5;
`;

const Input = styled.input`
  font-size: 16px;
  display: block;
  background: #efefef;
  border: 1px solid #ddd;
  padding: 1.5rem;
  padding-left: 5rem;
  width: 400px;
  box-sizing: border-box;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Textarea = styled.textarea`
  font-size: 16px;
  font-family: 'Inter';
  display: block;
  background: #efefef;
  border: 1px solid #ddd;
  padding: 1.25rem;
  padding-top: 4rem;
  width: 400px;
  max-width: 400px;
  box-sizing: border-box;

  @media (max-width: 900px) {
    width: 100%;
    min-width: 100%;
  }
`;

const Submit = styled.input`
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 600;
  background: #c29953;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  color: #fff;
  display: block;
  height: 68px;
  padding: 1.5rem;
  width: 400px;
  transition: all ease-in-out 0.3s;

  &:hover {
    background: #101119;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  position: relative;
  left: -0.5rem;

  img {
    width: 25px;
    margin: 0 0.5rem;
    filter: invert(1);
  }
`;

const PrivacyPage = () => {
  const [state, handleSubmit] = useForm('mpzbrwaz');

  return (
    <Main>
      <HeroImage src={background} />
      <Hero small={true}>
        <h1>
          <span>PNW</span>
          <span>Sports</span>
          <span>Group</span>
          <span>is</span>
          <span>headquartered</span>
          <span>and</span>
          <span>operates</span>
          <span>out</span>
          <span>of</span>
          <span>Vancouver,</span>
          <span>BC.</span>
        </h1>
      </Hero>

      <Section>
        <Wrapper>
          <Row>
            <ColSmall>
              <h2>Schedule a discovery call with our team.</h2>
              <p>
                Hiring the right agent could be the best play you make. From
                helping student-athletes navigate the draft process to
                representing professional NBA players, we offer comprehensive
                services tailored to meet and exceed our clients' needs. Contact
                the agency today to learn how putting us on your team can help
                your game.
              </p>

              <br />
              <h3>Contact information</h3>
              <Lead>
                info@pnwsportsgroup.com
                <br />
              </Lead>

              <Lead>
                Vancouver,
                <br />
                BC, Canada
              </Lead>

              <Icons>
                <a href="https://www.instagram.com/PNWSPORTSGROUP">
                  <img src={ig} alt="Instagram" />
                </a>
              </Icons>
            </ColSmall>

            <ColLarge>
              <Form
                action="https://formspree.io/f/mpzbrwaz"
                method="POST"
                onSubmit={handleSubmit}
              >
                <Field>
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" id="name" name="name" />
                </Field>

                <Field>
                  <Label htmlFor="email">Email</Label>
                  <Input type="email" id="email" name="_replyto" />
                </Field>

                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />

                <Field>
                  <Label htmlFor="phone">Phone</Label>
                  <Input type="tel" id="phone" name="phone" />
                </Field>

                <Field>
                  <Label htmlFor="message">Message</Label>
                  <Textarea id="message" name="message" rows="8" />
                </Field>

                <input type="text" name="_gotcha" style={{ display: 'none' }} />
                <Submit type="submit" value="Send Message" />

                {state.succeeded && (
                  <h3>
                    Thanks for your reaching out. <br /> We will get back to you
                    as soon as we can.
                  </h3>
                )}
              </Form>
            </ColLarge>
          </Row>
        </Wrapper>
      </Section>
    </Main>
  );
};

export default PrivacyPage;
